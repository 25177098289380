import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useMap, useMapEvents, Marker, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { SearchBar } from '../SearchBar';
import { StopCommand } from '../commands/StopCommand';
import { POICommand } from '../commands/POICommand';
import { ProviderCommand } from '../commands/ProviderCommand';
import { stopIcon, selectedStopIcon, ticketOfficeIconNetwork, selectedTicketOfficeIconNetwork, clusterIconNetwork } from '../leaflet/StopIcons';
import { Row, Col, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { RecenterButton } from '../RecenterButton';
import { FilterLinesButton } from '../FilterLinesButton';

export class StopPassingInfo extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        getMapCenter: PropTypes.func,
        setMapCenter: PropTypes.func,
        recenter: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "stopPassingInfo";
        this.state = {
            commands: {
                stops: new StopCommand(),
                poi: new POICommand(),
                provider: new ProviderCommand()
            },
            nearStops: [],
            nearTO: [],
            lines: [],
            linesToDisplay: [],
            linePaths: [],
            linePathsToDisplay: [],
            mapRadius: 227753,
            selectedStop: undefined,
            selectedTO: undefined,
            selectedLine: undefined,
            displayLinesDropdown: false,
            isLoadingPassings: false,
            isToRecenter: true,
            displayLinesFilter: false
        }
    }

    componentDidMount() {
        const { getState } = this.context;
        const state = getState(this.stateKey);

        if (undefined === state) {
            this.getNearStops();
            this.getNearPOI();
            this.getProviders();
        } else {
            state.isToRecenter = true;
            this.setState(state, () => this.getStopTimes());
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        setState(this.stateKey, this.state);
    }

    setMapCenter(center, radius) {
        const { setMapCenter } = this.context;

        setMapCenter([center.lat, center.lng], () => {
            //Set the new map radius and get data
            this.setState({
                mapRadius: radius
            }, () => {
                this.getNearStops();
                this.getNearPOI();
            });
        });
    }

    //NEAR STOPS
    getNearStops() {
        const { commands, mapRadius } = this.state;
        const { getMapCenter } = this.context;
        const mapCenter = getMapCenter();

        commands.stops.getNearStops(mapCenter[0], mapCenter[1], parseInt(mapRadius, 10), (r) => this.getNearStopsSuccessCallback(r));
    }

    getNearStopsSuccessCallback(result) {
        const { nearStops } = this.state;

        this.setState({
            nearStops: nearStops.concat(result.filter(r => nearStops.find(s => s.id === r.id) === undefined))
        });
    }
    //----------

    //NEAR Ticket Offices
    getNearPOI() {
        const { commands, mapRadius } = this.state;
        const { getMapCenter } = this.context;
        const mapCenter = getMapCenter();

        commands.poi.getNearPOI(mapCenter[0], mapCenter[1], parseInt(mapRadius, 10), (r) => this.getNearPOISuccessCallback(r))
    }

    getNearPOISuccessCallback(result) {
        const { nearTO } = this.state;

        this.setState({
            nearTO: nearTO.concat(result.filter(r => nearTO.find(s => s.id === r.id) === undefined))
        });
    }
    //----------

    //Get provider, provider lines & line paths
    getProviders() {
        const { commands } = this.state;
        commands.provider.getProviders((r) => this.providersSuccessCallback(r));
    }

    providersSuccessCallback(result) {
        if (1 === result.length) {
            this.getProviderLines(result[0]);
        }
    }

    getProviderLines(provider) {
        const { commands } = this.state;
        const providerName = undefined !== provider.name ? provider.name : "";
        commands.provider.getProviderLines(providerName, (r) => this.providerLinesSuccessCallback(r))
    }

    providerLinesSuccessCallback(result) {
        result.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({
            lines: result,
            linesToDisplay: [...result]
        }, () => {
            this.getProviderLinePaths();
        });
    }

    getProviderLinePaths() {
        const { commands, lines } = this.state;
        commands.provider.getProviderLinePaths(lines.map(function (obj) { return obj.id }).join(","), (r) => this.providerLinePathsSuccessCallback(r));
    }

    providerLinePathsSuccessCallback(result) {
        this.setState({
            linePaths: result,
            linePathsToDisplay: [...result]
        });
    }
    //----------

    //STOP TIMES
    getStopTimes() {
        this.setState({
            isToRecenter: false,
            isLoadingPassings: true
        });

        const { selectedStop, commands } = this.state;
        if (undefined !== selectedStop) {
            commands.stops.getStopTimes(selectedStop.provider, selectedStop.id, (r) => this.getStopTimesSuccessCallback(r));
        }
    }

    getStopTimesSuccessCallback(result) {
        const { selectedStop } = this.state;
        selectedStop.passings = result;

        this.setState({
            selectedStop: selectedStop,
            isLoadingPassings: false
        });
    }

    //Helper Functions
    handleSearchResultSelection(place, recenter) {
        if (place.type === 1) {
            this.handleStopSelection(place, recenter);
        } else if (place.type === 4) {
            this.handleTicketOfficeSelection(place, recenter);
        } else if (place.type === 3) {
            this.handleLineSelection(place);
        }

    }

    handleStopSelection(stop, recenter) {
        const { setMapCenter } = this.context;
        if (recenter) {
            setMapCenter([stop.coordX, stop.coordY], () => {
                this.getNearStops();
                this.getNearPOI();
            });
        }

        this.setState({
            selectedStop: stop,
            selectedTO: undefined,
            isToRecenter: recenter
        }, () => {
            //if stop, get stop times
            if (1 === stop.type) {
                this.getStopTimes();
            }
        });
    }

    handleTicketOfficeSelection(ticketOffice, recenter) {
        const { setMapCenter } = this.context;
        if (recenter) {
            setMapCenter([ticketOffice.coordX, ticketOffice.coordY], () => {
                this.getNearStops();
                this.getNearPOI();
            });
        }

        this.setState({
            selectedStop: undefined,
            selectedTO: ticketOffice,
            isToRecenter: recenter
        });
    }

    handleLineSelection(line) {
        const { lines, linePaths } = this.state;
        const { onSelectLine } = this.props;
        let selectedLine = lines.find(l => parseInt(l.id) === line.id);
        if (undefined !== line) {
            onSelectLine({ line: selectedLine, provider: selectedLine.provider, paths: linePaths.filter(p => p.lineId === parseInt(selectedLine.id)) });
        }
    }

    handlePathSelection(path) {
        const { lines, linePaths } = this.state;
        const { onSelectLine } = this.props;
        let line = lines.find(l => parseInt(l.id) === path.lineId);
        if (undefined !== line) {
            onSelectLine({ line: line, provider: line.provider, paths: linePaths.filter(p => p.lineId === parseInt(line.id)) });
        }
    }

    handleSearchClear() {
        this.setState({
            selectedStop: undefined,
            selectedTO: undefined
        });
    }

    handlePassingSelection(passing) {
        const { onSelectPassing } = this.props;
        const { selectedStop } = this.state;
        onSelectPassing({ passing: passing, provider: selectedStop.provider });
    }

    recenter() {
        const { recenter } = this.context;
        this.setState({
            isToRecenter: true
        }, () => {
            recenter(() => {
                this.getNearStops();
                this.getNearPOI();
            })
        });
    }

    filterLines() {
        const { displayLinesFilter } = this.state;

        this.setState({
            displayLinesFilter: !displayLinesFilter
        })
    }

    handleLineCheckboxClick(checkbox, line) {
        const { linesToDisplay, linePaths, linePathsToDisplay } = this.state;

        if (checkbox.target.checked) {
            linesToDisplay.push(line);
            let newPaths = linePathsToDisplay.concat(linePaths.filter(p => p.lineId === parseInt(line.id)));

            this.setState({
                linesToDisplay: linesToDisplay,
                linePathsToDisplay: newPaths
            });
        } else {
            let newLines = linesToDisplay.filter(l => l.id !== line.id);
            let newPaths = linePathsToDisplay.filter(p => p.lineId !== parseInt(line.id));

            this.setState({
                linesToDisplay: newLines,
                linePathsToDisplay: newPaths
            });
        }
    }

    determineStopIcon(stop) {
        const { selectedStop } = this.state;

        if (undefined !== selectedStop && selectedStop.id === stop.id) {
            return selectedStopIcon;
        }

        return stopIcon;
    }

    determineTOIcon(ticketOffice) {
        const { selectedTO } = this.state;

        if (undefined !== selectedTO && selectedTO.id === ticketOffice.id) {
            return selectedTicketOfficeIconNetwork;
        }

        return ticketOfficeIconNetwork;
    }

    formatDuration(duration) {
        if (duration <= 60) {
            return `${duration}min`;
        }

        let h = Math.floor(duration / 60);
        let m = duration % 60;
        m = m < 10 ? '0' + m : m;
        return `${h}h ${m}min`;
    }

    determinePathColor(path) {
        const { lines } = this.state;
        let line = lines.find(l => parseInt(l.id) === path.lineId);
        if (undefined !== line && 0 !== line.lineColor) {
            return `rgba(${line.lineColorFormatted.r},${line.lineColorFormatted.g},${line.lineColorFormatted.b},${line.lineColorFormatted.a})`;
        }
        return "#27aae1";
    }

    isCheckboxChecked(line) {
        const { linesToDisplay } = this.state;
        return linesToDisplay.findIndex(l => l.id === line.id) !== -1;
    }

    //--------------

    renderPassingInfos() {
        const { selectedStop, isLoadingPassings } = this.state;

        //If not loading, but selected stop or selected stop passings are undefined, return nothing
        if (!isLoadingPassings && (undefined === selectedStop || undefined === selectedStop.passings)) {
            return null;
        }

        return (
            <div className="passing-info-panel">
                <div className="passing-info-panel-header">
                    <div className="passing-info-panel-main-header d-flex justify-content-between">
                        <div className="passing-info-panel-main-header-text">
                            <Row>
                                <Col className="passing-info-panel-header-provider-info" xs={1} sm={1}>
                                    {this.renderProviderLogo(selectedStop, "25px")}
                                </Col>
                                <Col className="d-flex-inline text-wrap" xs={11} sm={11}>
                                    <b>{strings.nextDeparturesFrom}</b> <b>{selectedStop.name}</b> ({selectedStop.code})
                                </Col>
                            </Row>
                        </div>
                        <div className="icon-refresh2 refresh-button" onClick={() => this.getStopTimes()} />
                        <div className="icon-error close-button" onClick={() => this.handleSearchClear()} />
                    </div>

                    <div className="passing-info-panel-list-header">
                        <Row>
                            <Col xs={3} sm={3}>
                                <b>{strings.line}</b>
                            </Col>
                            <Col xs={6} sm={7} className="padding-left-5">
                                <b>{strings.destination}</b>
                            </Col>
                        </Row>
                    </div>
                </div>

                <ListGroup className="passings-list">
                    {this.renderPassingsList()}
                </ListGroup>
            </div >
        );
    }

    renderPassingsList() {
        const { selectedStop, isLoadingPassings } = this.state;

        if (isLoadingPassings) {
            return (
                <ListGroupItem key="stop-passing-spinner" className="text-align-center">
                    <Spinner animation="border" role="status" />
                </ListGroupItem>
            );
        }

        if (0 !== selectedStop.passings.length) {
            return (
                selectedStop.passings.map((passing, index) =>
                    <ListGroupItem key={`passing-${index}`} onClick={() => this.handlePassingSelection(passing)}>
                        <Row className="passings-list-passing-info-row">
                            <Col xs={3} sm={3}>
                                <b>{passing.lineCode}</b>
                            </Col>
                            <Col xs={6} sm={7} className="d-flex align-items-center passings-list-destination-info-col">
                                {passing.destination}
                            </Col>
                            <Col xs={3} sm={2} className={passing.isRT ? "rt-passing-info d-flex align-items-center justify-content-right white-space-no-wrap" : "d-flex align-items-center justify-content-right white-space-no-wrap"}>
                                {passing.isRT ? <img src='icons/tempo_real.gif' className="rt-passing-info-gif" alt="Tempo Real" /> : null}
                                {this.formatDuration(passing.duration)}
                            </Col>
                        </Row>
                    </ListGroupItem>
                )
            );
        } else {
            return (
                <ListGroupItem key={`passing-no-info}`}>
                    <Row>
                        <Col sm={12}>
                            {strings.noPassingsToShow}
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }
    }

    renderTicketOfficeInfo() {
        const { selectedTO } = this.state;

        if (undefined === selectedTO) {
            return null;
        }

        return (
            <div className="passing-info-panel">
                <div className="ticket-office-panel-header d-flex justify-content-between">
                    <div className="passing-info-panel-header-provider-info">
                        <div className="d-flex-inline text-wrap">
                            <b>{strings.ticketOffice}</b>
                        </div>
                    </div>
                    <div className="icon-error close-button" onClick={() => this.handleSearchClear()} />
                </div>
                <div className="passings-list ticket-office-description">
                    {selectedTO.description}
                </div>
            </div>
        );
    }

    renderProviderLogo(stop, height = "auto") {
        const { getLogo } = this.context;
        const imgUrl = getLogo(stop.provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img className="margin-right-5" height={height} src={imgUrl} alt={`${stop.provider}`} />
        );
    }

    renderLinesFilter() {
        const { lines } = this.state;
        return (
            <div className="stop-passing-info-filter-lines-panel">
                <div className="stop-passing-info-filter-lines-panel-list">
                    {
                        lines.map((line, index) =>
                            <ListGroupItem key={`line-${index}`}>
                                <Row className="lines-filter-line-row">
                                    <Col xs={11} sm={11}>
                                        <b>{line.name} ({line.code})</b>
                                    </Col>
                                    <Col xs={1} sm={1} className="d-flex align-items-center">
                                        <input type="checkbox" id={`${line.code}-checkbox`} checked={this.isCheckboxChecked(line)} onChange={(checkbox) => this.handleLineCheckboxClick(checkbox, line)} />
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        )
                    }
                </div>
            </div>
        );
    }

    renderPath(path) {
        if (undefined === path) {
            return null;
        }

        if (undefined === path.shape || null === path.shape || !Array.isArray(path.shape.segments) || 0 === path.shape.segments.length) {
            return (
                <Polyline
                    key={`line-path-${path.code}`}
                    positions={path.places.map(place => [place.coordX, place.coordY])}
                    color={this.determinePathColor(path)}
                    eventHandlers={{
                        click: () => {
                            this.handlePathSelection(path);
                        }
                    }} />
            );
        }

        return (
            <React.Fragment key={`line-path-${path.code}`}>
                {
                    path.shape.segments.map((segment, idx) => 
                        <Polyline
                            key={`line-path-${path.code}-${idx}`}
                            positions={segment.breakpoints.map(breakpoint => [breakpoint.latitude, breakpoint.longitude])}
                            color={this.determinePathColor(path)}
                            eventHandlers={{
                                click: () => {
                                    this.handlePathSelection(path);
                                }
                            }} />
                    )
                }
            </React.Fragment>
        );
    }

    render() {
        const { isToRecenter, nearStops, selectedStop, nearTO, selectedTO, linePathsToDisplay, displayLinesFilter } = this.state;
        const { getMapCenter } = this.context;

        return (
            <div className="stop-passing-info">
                <div className="stop-passing-info-panel">
                    <div className="stop-passing-info-actions-panel">
                        <div className="stop-passing-info-search-bar-panel">
                            <SearchBar
                                className="stop-passing-info-search-bar"
                                resultsClassName="next-departures-search-bar-results-list"
                                caller="nextdepartures" placeholder={strings.searchPlaceholder}
                                onSelect={(place, recenter) => this.handleSearchResultSelection(place, recenter)}
                                onSearchClear={() => this.handleSearchClear()}
                                isRequired={false} />


                            {displayLinesFilter ? this.renderLinesFilter() : null}
                        </div>
                        <div className="d-inline-flex stop-passing-info-action-buttons">
                            <RecenterButton className="margin-left-5" recenter={() => this.recenter()} />
                            <FilterLinesButton className={`margin-left-5 ${displayLinesFilter ? "filter-lines-button-active" : ""}`} filter={() => this.filterLines()} />
                        </div>
                    </div>

                    {undefined !== selectedStop ? this.renderPassingInfos() : null}
                    {undefined !== selectedTO ? this.renderTicketOfficeInfo() : null}
                </div>


                <MapContainer className="map-container" center={getMapCenter()} zoom={9} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                    <MapEvents setCenter={(center, radius) => this.setMapCenter(center, radius)} />
                    <SetViewOnClick coords={getMapCenter()} isToRecenter={isToRecenter} />
                    {linePathsToDisplay.map((path) =>
                        this.renderPath(path)
                    )}
                    <MarkerClusterGroup spiderfyOnMaxZoom={false} disableClusteringAtZoom={16} showCoverageOnHover={false} iconCreateFunction={clusterIconNetwork}>
                        {nearStops.map((stop) =>
                            <Marker
                                key={`stop-${stop.code}${stop.provider}`}
                                position={[stop.coordX, stop.coordY]}
                                icon={this.determineStopIcon(stop)}
                                eventHandlers={{
                                    click: () => {
                                        this.handleStopSelection(stop, false)
                                    }
                                }}>

                                <Tooltip>
                                    {this.renderProviderLogo(stop, "15px")}
                                    <strong>{stop.name}</strong>
                                    ({stop.code})
                                </Tooltip>
                            </Marker>
                        )}
                        {nearTO.map((ticketOffice) =>
                            <Marker
                                key={`to-${ticketOffice.code}`}
                                position={[ticketOffice.coordX, ticketOffice.coordY]}
                                icon={this.determineTOIcon(ticketOffice)}
                                eventHandlers={{
                                    click: () => {
                                        this.handleTicketOfficeSelection(ticketOffice, false)
                                    }
                                }}>

                                <Tooltip>
                                    <strong>{ticketOffice.name}</strong>
                                </Tooltip>
                            </Marker>
                        )}
                    </MarkerClusterGroup>
                </MapContainer>
            </div>
        );
    }
}

function SetViewOnClick({ coords, isToRecenter }) {
    const map = useMap();

    if (isToRecenter) {
        map.setView(coords, map.getZoom());
    }

    return null;
}

function MapEvents(args) {
    useMapEvents({
        dragend: (e) => {
            const radius = e.target.getCenter().distanceTo(e.target.getBounds().getNorthWest());
            args.setCenter(e.target.getCenter(), radius);
        },
        zoomend: (e) => {
            const radius = e.target.getCenter().distanceTo(e.target.getBounds().getNorthWest());
            args.setCenter(e.target.getCenter(), radius);
        }
    });
    return null;
}
