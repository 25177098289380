import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Spinner, Overlay, Popover } from 'react-bootstrap';
import { strings } from '../../resources/strings';
import { ProviderCommand } from '../commands/ProviderCommand';
import { ScheduleCommand } from '../commands/ScheduleCommand';
import { TripCommand } from '../commands/TripCommand';
import { Disclaimer } from '../Disclaimer';

export class Schedules extends Component {
    static contextTypes = {
        getState: PropTypes.func,
        setState: PropTypes.func,
        getLogo: PropTypes.func,
        getMenuState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.stateKey = "schedules";

        this.state = {
            commands: {
                provider: new ProviderCommand(),
                schedule: new ScheduleCommand(),
                trip: new TripCommand()
            },
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            providers: [],
            selectedProvider: undefined,
            lines: [],
            selectedLine: undefined,
            selectedDirection: undefined,
            dayTypes: [],
            selectedDaytype: undefined,
            displayLineDropdown: false,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
            isLoadingInfo: false,
            displayNoInfo: false,
            displayExceptionsPopover: false,
        };
    }

    componentDidMount() {
        const { getState } = this.context;
        const { match, lineId } = this.props;
        const state = getState(this.stateKey);

        let params = undefined;
        if (undefined !== match) {
            params = match.params;
        }

        if (undefined === state || (undefined !== params && params.lineId !== undefined) || lineId !== undefined) {
            this.getProviders();
        } else {
            this.setState(state);
        }
    }

    componentWillUnmount() {
        const { setState } = this.context;
        const { lineId } = this.props;

        if (undefined === lineId) {
            setState(this.stateKey, this.state);
        }
    }

    //DATA
    getProviders() {
        const { commands } = this.state;
        commands.provider.getProviders((r) => this.providersSuccessCallback(r));
    }

    providersSuccessCallback(result) {
        const { displayLineDropdown } = this.props;

        if (1 === result.length) {
            this.getProviderLines(result[0]);
        }

        this.setState({
            providers: result,
            displayLineDropdown: undefined !== displayLineDropdown ? displayLineDropdown : true
        });
    }

    getProviderLines(provider) {
        const { commands } = this.state;
        const providerName = undefined !== provider.name ? provider.name : "";
        commands.provider.getProviderLines(providerName, (r) => this.providerLinesSuccessCallback(r))
    }

    providerLinesSuccessCallback(result) {
        const { match, lineId, direction, displayLineDropdown } = this.props;
        let params = undefined;

        if (undefined !== match) {
            params = match.params;
        }

        this.setState({
            lines: result.sort((a, b) => a.name.localeCompare(b.name)),
            displayLineDropdown: undefined !== displayLineDropdown ? displayLineDropdown : true,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
        }, () => {
            this.setLine(result.find(l => (undefined !== params && l.id === params.lineId) || l.id === lineId));
            this.setDirection(undefined !== params ? params.direction : direction);
        });
    }

    getDaytypes() {
        const { selectedLine, commands } = this.state;
        commands.schedule.getScheduleByLineGroupByDayType(selectedLine.id, (r) => this.dayTypesSuccessCallback(r));
    }

    dayTypesSuccessCallback(result) {
        if (0 === result.length) {
            this.setState({
                displayNoInfo: true
            });
        } else if (1 === result.length) {
            this.setState({
                dayTypes: result,
                selectedDaytype: result[0],
                displayDaytypeDropdown: true,
                isLoadingInfo: true
            }, () => {
                this.getTrips();
            });
        } else {
            this.setState({
                dayTypes: result,
                displayDaytypeDropdown: true
            });
        }
    }

    getTrips() {
        const { commands, selectedLine, selectedDirection, selectedDaytype } = this.state;
        commands.trip.getTripsByLineAndDayType(
            selectedLine.id, selectedDirection, selectedDaytype.schedules.join(), (r) => this.getTripsSuccessCallback(r));
    }

    getTripsSuccessCallback(result) {
        if (result.trips.length > 0) {
            this.setState({
                scheduleInfo: result,
                isLoadingInfo: false
            });
        } else {
            this.setState({
                displayNoInfo: true,
                isLoadingInfo: false
            });
        }
    }

    //---------

    //FUNCTIONS

    setProvider(provider) {
        this.setState({
            selectedProvider: provider,
            selectedLine: undefined,
            selectedDirection: undefined,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayLineDropdown: false,
            displayDirectionDropdown: false,
            displayDaytypeDropdown: false,
            displayNoInfo: false
        }, () => {
            this.getProviderLines(provider);
        });
    }

    setLine(line) {
        if (line === undefined) {
            return;
        }

        this.setState({
            selectedLine: line,
            selectedDirection: undefined,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayDirectionDropdown: true,
            displayDaytypeDropdown: false,
            displayNoInfo: false
        });
    }

    setDirection(direction) {
        if (direction === undefined) {
            return;
        }

        this.setState({
            selectedDirection: direction,
            selectedDaytype: undefined,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayDaytypeDropdown: false,
            displayNoInfo: false
        }, () => {
            this.getDaytypes();
        });
    }

    determineDirectionString(direction) {
        switch (direction) {
            case "G":
                return strings.directionGo;
            case "R":
                return strings.directionReturn;
            case "C":
                return strings.directionCircular;
            default:
                return "";
        }
    }

    setDaytype(daytype) {
        this.setState({
            selectedDaytype: daytype,
            isLoadingInfo: true,
            scheduleInfo: { stops: [], trips: [], exceptions: [] },
            displayNoInfo: false
        }, () => {
            this.getTrips();
        });
    }

    setDisplayExceptionsPopover(displayExceptionsPopover) {
        this.setState({
            displayExceptionsPopover: displayExceptionsPopover
        });
    }
    //---------------

    //RENDER

    renderDropdowns() {
        return (
            <div>
                {this.renderProviderDropdown()}
                {this.renderLinesDropdown()}
                {this.renderDirectionsDropdown()}
                {this.renderDaytypesDropdown()}
            </div>
        );
    }

    renderProviderDropdown() {
        const { providers, selectedProvider } = this.state;

        if (1 >= providers.length) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-provider-button" id="providerDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedProvider !== undefined ?
                            <div className="overflow-x-clip">
                                {this.renderProviderLogo(selectedProvider.name)}
                                &nbsp;
                                <span className="schedules-provider-dropdown-list-option">{selectedProvider.name}</span>
                            </div>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.provider}</span>
                    }
                </Button>

                <ul className="dropdown-menu schedules-provider-dropdown-list" aria-labelledby="providerDropdown">
                    {
                        providers.map(provider =>
                            <li onClick={() => this.setProvider(provider)}>
                                <div className="dropdown-item">
                                    {this.renderProviderLogo(provider.name)}
                                    &nbsp;
                                    <span className="schedules-provider-dropdown-list-option">{provider.name}</span>
                                </div>
                            </li>
                        )
                    }
                </ul>

            </div>
        );
    }

    renderProviderLogo(provider) {
        const { getLogo } = this.context;
        const imgUrl = getLogo(provider);

        if (null === imgUrl) {
            return null;
        }

        return (
            <img height="20px" src={imgUrl} alt={`${provider}`} />
        );
    }

    renderLinesDropdown() {
        const { lines, selectedLine, displayLineDropdown } = this.state;

        if (!displayLineDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-line-button" id="lineDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedLine !== undefined ?
                            <span className="overflow-x-clip">
                                <b>{selectedLine.code}</b>
                                &nbsp;
                                {selectedLine.name}
                            </span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.line}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-line-dropdown-list" aria-labelledby="lineDropdown">
                    {
                        lines
                        .sort((a, b) => a.code - b.code) 
                        .map((line, index) =>
                            <li key={`line-${index}`} onClick={() => this.setLine(line)}>
                                <span className="dropdown-item">
                                    <b>{line.code}</b>
                                    &nbsp;
                                    {line.name}
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderDirectionsDropdown() {
        const { selectedLine, selectedDirection, displayDirectionDropdown } = this.state;

        if (!displayDirectionDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-direction-button" id="directionDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedDirection !== undefined ?
                            <span className="overflow-x-clip">{this.determineDirectionString(selectedDirection)}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.direction}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-direction-dropdown-list" aria-labelledby="directionDropdown">
                    {
                        undefined !== selectedLine.directions ?
                            selectedLine.directions.map((direction, index) =>
                                <li key={`direction-${index}`} onClick={() => this.setDirection(direction)}>
                                    <span className="dropdown-item">
                                        {this.determineDirectionString(direction)}
                                    </span>
                                </li>
                            )
                            :
                            null
                    }
                </ul>
            </div>
        );
    }

    renderDaytypesDropdown() {
        const { displayDaytypeDropdown, dayTypes, selectedDaytype } = this.state;

        if (!displayDaytypeDropdown) {
            return null;
        }

        return (
            <div className="dropdown margin-bottom-10">
                <Button variant="secondary" className="dropdown-toggle schedules-daytype-button" id="daytypeDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                        selectedDaytype !== undefined ?
                            <span className="overflow-x-clip">{selectedDaytype.name}</span>
                            :
                            <span className="text-color-placeholder overflow-x-clip">{strings.daytype}</span>
                    }
                </Button>
                <ul className="dropdown-menu schedules-daytype-dropdown-list" aria-labelledby="daytypeDropdown">
                    {
                        dayTypes.map((daytype, index) =>
                            <li key={`daytype-${index}`} onClick={() => this.setDaytype(daytype)}>
                                <span className="dropdown-item">
                                    {daytype.name}
                                </span>
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }

    renderScheduleInfo() {
        const { isLoadingInfo, scheduleInfo, displayNoInfo } = this.state;

        if (isLoadingInfo) {
            return (
                <div className="width-100 text-align-center">
                    <Spinner animation="border" role="status" style={{ color: "#e0c5a2" }} />
                </div>
            );
        }

        if (displayNoInfo) {
            return (
                <div className="schedules-no-info-to-show">
                    <h5>{strings.noInfoToShow}</h5>
                </div>
            );
        }

        const hasExceptions = Array.isArray(scheduleInfo.exceptions) && 0 < scheduleInfo.exceptions.length;

        return (
            <div className="schedules-schedule-info">
                <div className="schedules-schedule-trip-info">
                    {this.renderStopsList(scheduleInfo, hasExceptions)}
                    {this.renderTripList(scheduleInfo, hasExceptions)}
                </div>

                {
                    //0 !== scheduleInfo.exceptions.length ?
                    //    <div className="schedules-schedule-exception-info">
                    //        {this.renderExceptionList(scheduleInfo)}
                    //    </div>
                    //    :
                    //    null
                }
            </div>
        );
    }

    renderStopsList(scheduleInfo, hasExceptions) {
        let exceptionsList = null
        if (hasExceptions) {
            const { displayExceptionsPopover } = this.state;
            const overlayRef = React.createRef();

            exceptionsList = (
                <div ref={overlayRef}
                    className="schedules-schedule-notes-info"
                    onClick={() => this.setDisplayExceptionsPopover(!displayExceptionsPopover)}
                    onMouseEnter={() => this.setDisplayExceptionsPopover(true)}
                    onMouseLeave={() => this.setDisplayExceptionsPopover(false)}>
                    <span className="icon icon-info">{strings.notes}</span>
                    {this.renderExceptionList(scheduleInfo, displayExceptionsPopover, overlayRef)}
                </div>
            );
        }

        return (
            <div className="schedules-schedule-stop-info">
                    {exceptionsList}
                    {
                        scheduleInfo.stops.map((stop, index) =>
                            <div key={`stop-${index}`}>
                                {stop.name}
                            </div>
                        )
                    }
            </div>
        );
    }

    renderTripList(scheduleInfo, hasExceptions) {
        return (
            <div className="schedules-schedule-passing-info">
                {
                    scheduleInfo.trips.map((trip, index) =>
                        <div key={`trip-${index}`} className="schedules-schedule-passing-info-list">
                                {hasExceptions ? this.renderTripExceptions(trip) : null}
                                {this.renderTripPassings(trip, scheduleInfo.stops)}
                        </div>
                    )
                }
            </div>
        );
    }

    renderTripExceptions(trip) {
        const exceptions = trip
            .filter(passing => Array.isArray(passing.exceptions) && 0 < passing.exceptions.length)
            .reduce((output, passing) => {
                passing.exceptions.forEach(exception => {
                    if (!output.includes(exception)) {
                        output.push(exception);
                    }
                });

                return output;
            }, []).sort((a, b) => a.localeCompare(b)).join(", ");

        return (
            <div className="schedules-schedule-passing-info-exceptions">
                {"" === exceptions ? <span>&nbsp;</span> : exceptions}
            </div>
        );
    }

    renderTripPassings(trip, stops) {
        return stops.map(stop => {
            const passing = trip.find(passing => passing.stopId === stop.id && passing.stopOrder === stop.order);
            if (undefined === passing) {
                return (
                    <div key={`undefined-time-${trip.order}-${stop.id}-${stop.order}`} className="schedules-trip-passing-list-value">
                        --:--
                    </div>
                );
            }

            return (
                <div key={`time-${trip.order}-${stop.id}-${stop.order}`} className="schedules-trip-passing-list-value">
                    {passing.time}
                </div>
            );
        });
    }

    renderExceptionList(scheduleInfo, displayExceptionsPopover, overlayRef) {
        return (
            <Overlay target={overlayRef} show={displayExceptionsPopover} placement="bottom-start">
                <Popover id="popover-exceptions" placement="bottom">
                    <Popover.Body>
                        <div className="schedules-schedule-exception-info-list">
                            {
                                scheduleInfo.exceptions.map(exception =>
                                    <div key={`schedule-${exception}`}>
                                        {exception}
                                    </div>
                                )
                            }
                        </div>
                    </Popover.Body>
                </Popover>
            </Overlay>
        );
    }

    render() {
        const isMenuOpen = this.context.getMenuState();
        return (
            <div className="schedules-panel">
                {this.renderDropdowns()}
                {this.renderScheduleInfo()}
                <Disclaimer marginClass={isMenuOpen ? "margin-left-250" : "margin-left-50"} />
            </div>
        );
    }
}
