import L from 'leaflet';

const stopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/stopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const selectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedStopIcon.png'),
    iconAnchor: new L.Point(12.5, 12.5),
    iconSize: new L.Point(25, 25)
});

const ticketOfficeIconNetwork = new L.Icon({
    iconUrl: require('../leaflet/icons/ticketOfficeIconNetwork.png'),
    iconAnchor: new L.Point(25, 25),
    iconSize: new L.Point(50, 50)
});

const ticketOfficeIconPlanRoute = new L.Icon({
    iconUrl: require('../leaflet/icons/ticketOfficeIconPlanRoute.png'),
    iconAnchor: new L.Point(25, 25),
    iconSize: new L.Point(50, 50)
});

const ticketOfficePageIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/ticketOffice.png'),
    iconAnchor: new L.Point(25, 25),
    iconSize: new L.Point(50, 50)
});

const selectedTicketOfficeIconNetwork = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedTicketOfficeIconNetwork.png'),
    iconAnchor: new L.Point(25, 25),
    iconSize: new L.Point(50, 50)
});

const selectedTicketOfficeIconPlanRoute = new L.Icon({
    iconUrl: require('../leaflet/icons/selectedTicketOfficeIconPlanRoute.png'),
    iconAnchor: new L.Point(25, 25),
    iconSize: new L.Point(50, 50)
});

const lineInfoStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const lineInfoSelectedStopIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/lineInfoSelectedStopIcon.png'),
    iconAnchor: new L.Point(7.5, 7.5),
    iconSize: new L.Point(15, 15)
});

const routeOriginIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeOriginIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

const routeDestinationIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/routeDestinationIcon.png'),
    iconAnchor: new L.Point(15, 15),
    iconSize: new L.Point(30, 30)
});

const vehicleIcon = new L.Icon({
    iconUrl: require('../leaflet/icons/vehicle.png'),
    iconAnchor: new L.Point(20, 10),
    iconSize: new L.Point(40, 20)
});

const clusterIconNetwork = (cluster) => {
    let markerCount = cluster.getChildCount();
    let html = '<div>' + markerCount + '</div>';
    return L.divIcon({ html: html, className: 'marker-cluster-network', iconSize: L.point(50, 50), iconAnchor: L.point(25, 25) });
}

const clusterIconPlanRoute = (cluster) => {
    let markerCount = cluster.getChildCount();
    let html = '<div>' + markerCount + '</div>';
    return L.divIcon({ html: html, className: 'marker-cluster-plan-route', iconSize: L.point(50, 50), iconAnchor: L.point(25, 25) });
}

export { stopIcon, selectedStopIcon, selectedTicketOfficeIconPlanRoute, selectedTicketOfficeIconNetwork,ticketOfficeIconPlanRoute,ticketOfficeIconNetwork, lineInfoStopIcon, lineInfoSelectedStopIcon, routeOriginIcon, routeDestinationIcon, vehicleIcon, clusterIconNetwork,clusterIconPlanRoute, ticketOfficePageIcon };
