import React, { Component } from 'react';
import { Container, Row} from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class PrivacyPolicy extends Component {
    render() {
        return (
            <Container fluid className="mb-5 px-md-6">
                <Row className="mt-5">
                    <h5>{strings.privacyPolicy}</h5>
                </Row>
                <Row className="mt-5">
                    <p>Leia por favor esta Política de Privacidade com atenção, pois no acesso a este site,
                        a disponibilização dos seus dados pessoais implica o conhecimento e aceitação das
                        condições aqui constantes.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quem é o responsável pela recolha e tratamento dos dados?</b></p>
                    <p>A TAL - Transportes do Alentejo Litoral, S.A. é a entidade responsável pela recolha e tratamento dos dados pessoais, 
                        podendo, no âmbito da sua atividade, recorrer a entidades por si subcontratadas para a prossecução das 
                        finalidades aqui indicadas.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quem é o Encarregado de Proteção de dados?</b></p>
                    <p>O Encarregado de Proteção de Dados é a Protect Data (Best Privacy Consulting Unipessoal Limitada), 
                        representada por Ana Fazendeiro, que pode ser contactada pelo 
                        seguinte endereço de e-mail: <a href="mailto: privacidade@rodalentejo.pt">privacidade@rodalentejo.pt</a> </p>
                </Row>
                <Row className="mt-5">
                    <p><b>O que são dados pessoais?</b></p>
                    <p>Dados pessoais são qualquer informação, de qualquer natureza e
                        independentemente do respetivo suporte, incluindo som e imagem, relativa a uma
                        pessoa singular identificada ou identificável. É considerada identificável a pessoa
                        que possa ser identificada direta ou indiretamente, designadamente por referência a
                        um número de identificação ou a um ou mais elementos específicos da sua
                        identidade física, fisiológica, psíquica, económica, cultural ou social.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Que dados pessoais são recolhidos e tratados?</b></p>
                    <p>A TAL - Transportes do Alentejo Litoral, S.A. procede à recolha e ao tratamento, entre outros, 
                        dos seguintes dados pessoais: nome, número de telefone e endereço de correio eletrónico.</p>
                    <p>A TAL - Transportes do Alentejo Litoral, S.A. assume que os dados foram fornecidos pelo titular 
                        dos mesmos ou que este deu autorização para o efeito e presume que os mesmos são verdadeiros 
                        e se encontram atualizados. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>De que forma são recolhidos os seus dados?</b></p>
                    <p>Os dados pessoais podem ser recolhidos através dos seguintes meios:</p>
                    <p>
                        <ol type="a">
                            <li>Correio electrónico;</li>
                            <li>Website;</li>
                            <li>Chamadas telefónicas;</li>
                            <li>Presencialmente;</li>
                            <li>Através de aplicações (“apps”) instaladas em dispositivos móveis.</li>
                        </ol>
                    </p>
                    <p>Os dados recolhidos são processados e armazenados informaticamente e no 
                        estrito cumprimento da legislação de proteção de dados pessoais sendo 
                        armazenados em base de dados específicas, criadas para o efeito, pela 
                        TAL - Transportes do Alentejo Litoral, S.A. ou pelas entidades por esta subcontratadas.</p>
                    <p>Alguns dados pessoais recolhidos no website são de preenchimento obrigatório e, 
                        em caso de falta ou insuficiência desses dados, a TAL - Transportes do Alentejo Litoral, S.A. 
                        poderá não conseguir prestar-lhe os serviços ou as informações por si solicitadas. 
                        Em cada caso concreto, a TAL - Transportes do Alentejo Litoral, S.A. informá-lo-á da natureza 
                        obrigatória do fornecimento dos dados pessoais em causa.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quais as finalidades e fundamentos para o tratamento dos seus dados?</b></p>
                    <p>De acordo com os Princípios da proteção de dados a TAL - Transportes do Alentejo Litoral, S.A. 
                        só poderá proceder ao tratamento dos seus dados pessoais para finalidades determinadas e 
                        se tiver base legal para o fazer. A TAL - Transportes do Alentejo Litoral, S.A. utiliza 
                        os seus dados para os seguintes fins e com base nos seguintes fundamentos:</p>
                    <p><ol type="I">
                        <li>
                            <b>Tendo como fundamento o contrato de serviços de transporte que celebrou connosco, 
                            tratamos os seus dados para efeitos de:</b>
                            <ol type="a">
                                <li>Gestão de relação contratual dos serviços prestados pela TAL - Transportes do Alentejo Litoral, S.A.; e</li>
                                <li>Apoio ao cliente.</li>
                            </ol>
                        </li>
                        <li>
                            <b>Porque estamos no âmbito de diligências pré-contratuais tratamos os seus dados para efeitos de: </b>
                            <ol type="a" start="3">
                                <li>Pedidos de orçamento e informações; e</li>
                                <li>Recrutamento. </li>
                            </ol>
                        </li>
                        <li>
                            <b>Para cumprimento de obrigações legais, tratamos os seus dados para efeitos de:</b>
                            <ol type="a" start="5">
                                <li>Gestão de reclamações; e </li>
                                <li>Cumprimento de outras obrigações legais ou regulamentares.</li>
                            </ol>
                        </li>
                        <li>
                            <b>Porque é nosso cliente e temos interesse legítimo em querer prestar-lhe, cada vez mais, 
                                um serviço melhor, tratamos os seus dados para efeitos de:</b>
                            <ol type="a" start="7">
                                <li>Agenda de contactos; e </li>
                                <li>Ações de marketing. </li>
                            </ol>
                        </li>
                        <li>
                            <b>Tendo como fundamento o seu consentimento, tratamos os seus dados para efeitos de: </b>
                            <ol type="a" start="9">
                                <li>Envio de informações relativas a iniciativas de formação ou estágios; e </li>
                                <li>Envio de ações de marketing (caso não seja nosso cliente).</li>
                            </ol>
                        </li>
                    </ol>
                    </p>
                    <p>Para a prossecução das finalidades acima identificadas a TAL - Transportes do Alentejo Litoral, 
                        S.A. poderá proceder à interconexão dos dados recolhidos, com o propósito de atualizar e completar tais dados.”</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Com quem são partilhados os seus dados?</b></p>
                    <p>Os dados recolhidos e detidos pela TAL - Transportes do Alentejo Litoral, S.A. poderão ser transmitidos, com respeito 
                        pelo dever da confidencialidade e pelo princípio da finalidade que presidiu à sua recolha, para as seguintes entidades:</p>
                    <p>
                        <ol type="a">
                            <li>Entidades do grupo em que a TAL - Transportes do Alentejo Litoral, S.A. está inserida; </li>
                            <li>Autoridades judiciais ou administrativas, nos casos em que tal cedência seja obrigatória; </li>
                            <li>Empresas de recrutamento e seleção; </li>
                            <li>Subcontratantes que procederão ao tratamento dos dados por conta da TAL - Transportes do Alentejo Litoral, S.A. 
                                e de acordo com as finalidades por esta determinadas. </li>
                        </ol>
                    </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quais são os seus direitos? </b></p>
                    <p>Nos termos da legislação sobre Proteção de Dados Pessoais, garantimos-lhe o direito de acesso, 
                        atualização, retificação, eliminação, portabilidade e apagamento dos seus dados pessoais. 
                        Poderá ainda apresentar reclamações perante a Comissão Nacional da Proteção de Dados. </p>
                    <p>Conferimos-lhe, também, o direito de retirar o consentimento (não clientes) ou opor-se à 
                        utilização (clientes) dos dados facultados para fins de marketing, para o envio de comunicações 
                        informativas ou de inclusão em listas ou serviços informativos. Caso não o tenha efetuado aquando 
                        da recolha dos dados, poderá enviar um pedido posteriormente. </p>
                    <p>O exercício destes direitos deverá ser realizado através do seguinte endereço de correio eletrónico 
                        privacidade@rodalentejo.pt ou morada Estação Central de Camionagem de Évora, 2.º Piso, Avenida Túlio espanca, s/n, 7005-840 Évora. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Por quanto tempo são armazenados os seus dados?</b></p>
                    <p>O período de tempo durante o qual os dados são armazenados e conservados varia de acordo com a finalidade para a qual a informação é tratada. 
                        Sempre que não exista uma exigência legal específica, os dados serão armazenados e conservados apenas pelo período 
                        mínimo necessário para as finalidades que motivaram a sua recolha ou o seu posterior tratamento, findo 
                        o qual os mesmos serão eliminados. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Os seus dados são tratados de forma segura?</b></p>
                    <p>A TAL - Transportes do Alentejo Litoral, S.A. assume o compromisso de garantir a proteção da segurança
                        dos seus dados. Para o efeito, adotou diversas medidas de segurança, de caráter técnico e organizativo, 
                        de forma a proteger os dados pessoais que nos disponibiliza contra a sua difusão, perda, uso indevido, 
                        alteração, tratamento ou acesso não autorizado, bem como contra qualquer outra forma de tratamento ilícito. 
                        A TAL - Transportes do Alentejo Litoral, S.A. exige dos seus subcontratantes e dos seus parceiros a adoção 
                    de medidas de segurança equivalentes àquelas que pratica. </p>
                    <p>Não obstante as medidas de segurança praticadas pela TAL - Transportes do Alentejo Litoral, S.A. 
                        o utilizador deve adotar medidas adicionais de segurança designadamente, assegurar que utiliza 
                        equipamentos e um Browser atualizados em termos de segurança adequadamente configurados, 
                        com firewall ativa, antivírus e anti- spyware e, certificar-se da autenticidade dos sites 
                        que visita na internet, devendo evitar websites em cuja reputação não confie. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Comunicações</b></p>
                    <p>A TAL - Transportes do Alentejo Litoral, S.A. reconhece que poderá comunicar dados dos 
                        Utilizadores no âmbito de processos de fusão, aquisição e/ou incorporação em que se encontre, 
                        não se considerando essa comunicação como uma transferência de dados para terceiros, 
                        nem existindo qualquer tratamento subcontratado. </p>
                    <p>A TAL - Transportes do Alentejo Litoral, S.A. poderá ainda transmitir dados a terceiros 
                        no âmbito de investigações, inquéritos e processos judiciais e/ou administrativos ou 
                        de natureza semelhante, desde que para tal seja devidamente ordenada por ordem judicial nesse sentido. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Transferência dos Dados </b></p>
                    <p>Caso possam ocorrer transferências de dados para países terceiros que não pertençam à União 
                        Europeia ou ao Espaço Económico Europeu, a TAL - Transportes do Alentejo Litoral, S.A. cumprirá com a 
                        lei, nomeadamente no que respeita à adequabilidade do país de destino no que respeita a proteção 
                        de dados pessoais e aos requisitos que são aplicáveis a estas transferências, não sendo 
                        transferidos dados pessoais para jurisdições que não ofereçam garantias de segurança e proteção. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Alterações à Política de Privacidade </b></p>
                    <p>A TAL - Transportes do Alentejo Litoral, S.A. reserva-se o direito de, a qualquer altura, proceder a reajustamentos 
                        ou alterações à presente Política de Privacidade, sendo essas alterações devidamente publicitadas neste site. </p>
                </Row>
            </Container>
        );
    }
}