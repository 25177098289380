import React, { Component } from 'react';
import { useMap, MapContainer, TileLayer, Pane, Polyline, Marker, Tooltip } from 'react-leaflet';
import { lineInfoStopIcon, lineInfoSelectedStopIcon, vehicleIcon } from '../leaflet/StopIcons';
import 'leaflet/dist/leaflet.css';
import { TripCommand } from '../commands/TripCommand';

export class LineInfoMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            commands: {
                trips: new TripCommand()
            },
            vehiclePosition: {
                latitude: 0,
                longitude: 0
            },
            intervalId: null,
            isToRecenter: true
        }
    }

    componentDidMount() {
        const { realTimePassing } = this.props;

        if (realTimePassing !== undefined) {
            //Get bus position immediately
            this.onUpdateBusPosition();

            const id = setInterval(() => this.onUpdateBusPosition(), 20000);
            this.setState({
                intervalId: id
            });
        }
    }

    componentWillUnmount() {
        const { intervalId } = this.state;
        clearInterval(intervalId);
    }

    //HELPER FUNCTIONS

    onUpdateBusPosition() {
        const { commands } = this.state;
        const { realTimePassing, provider } = this.props;
        commands.trips.getVehiclePosition(provider, realTimePassing.vehicleNr, realTimePassing.vehicleTrip, (a) => this.onUpdateBusPositionSuccessCallback(a));
    }

    onUpdateBusPositionSuccessCallback(result) {
        const { trip } = this.props;

        this.setState({
            vehiclePosition: result,
            isToRecenter: undefined !== trip ? false : true
        });
    }

    getBounds(trip) {
        if (undefined === trip) {
            return [{ lat: 41.1483096, lng: -8.6108148 }, { lat: 41.1442942, lng: -8.6105935 }];
        }

        if (undefined === trip.shape || null === trip.shape || !Array.isArray(trip.shape.segments) || 0 === trip.shape.segments.length) {
            return [{ lat: trip.maxLatitude, lng: trip.maxLongitude }, { lat: trip.minLatitude, lng: trip.minLongitude }];
        }

        return [{ lat: trip.shape.maxLatitude, lng: trip.shape.maxLongitude }, { lat: trip.shape.minLatitude, lng: trip.shape.minLongitude }];
    }

    /*This is used to change the Tooltip permanent prop, which is immutable and can't be changed after creating the Tooltip.
    * But since this is used in the key prop, it will trigger the re-render and subsquently change the permanent prop value.
    */
    isPermanent(tripPassing) {
        const { selectedTripPassing } = this.props;
        return selectedTripPassing !== undefined && selectedTripPassing.stopId === tripPassing.stopId
    }

    //------------------------

    renderPath(trip) {
        if (undefined === trip || undefined === trip.passings) {
            return null;
        }

        if (undefined === trip.shape || null === trip.shape || !Array.isArray(trip.shape.segments) || 0 === trip.shape.segments.length) {
            return (
                <div key="polyLine-line-info">
                    <Polyline positions={trip.passings.map(passing => [passing.x, passing.y])} color="#27aae1" />
                </div>
            );
        }

        return (
            <div key="polyLine-line-info">
                {
                    trip.shape.segments.map((segment, idx) =>
                        <Polyline key={`trip-path-segment-${idx}`} positions={segment.breakpoints.map(breakpoint => [breakpoint.latitude, breakpoint.longitude])} color="#27aae1" />
                    )
                }
            </div>
        );
    }

    renderPathStops(trip) {
        if (undefined === trip || undefined === trip.passings) {
            return null;
        }

        const { vehiclePosition } = this.state;
        const { passing } = this.props;

        return (
            <div>
                {
                    trip.passings.map((tripPassing, idx) =>
                        <Marker
                            key={`marker-${idx}`}
                            position={[tripPassing.x, tripPassing.y]}
                            icon={passing.stopId === tripPassing.stopId ? lineInfoSelectedStopIcon : lineInfoStopIcon}>
                            {this.renderTooltip(tripPassing)}
                        </Marker>
                    )
                }
                {
                    vehiclePosition.latitude !== 0 && vehiclePosition.longitude !== 0
                        ? <Marker
                            key={`bus-position`}
                            position={[vehiclePosition.latitude, vehiclePosition.longitude]}
                            icon={vehicleIcon}>
                        </Marker>
                        : null
                }
            </div>
        );
    }

    renderTooltip(tripPassing) {
        const { makeTooltipsPermanent } = this.props;

        if (makeTooltipsPermanent) {
            return (
                <Tooltip key={`tooltip-${tripPassing.order}-${this.isPermanent(tripPassing)}`} permanent={this.isPermanent(tripPassing)}>
                    <span><b>{tripPassing.name}</b> ({tripPassing.stopCode})</span>
                </Tooltip>
            );
        }

        return (
            <Tooltip key={`tooltip-${tripPassing.order}`}>
                <span><b>{tripPassing.name}</b> ({tripPassing.stopCode})</span>
            </Tooltip>
        );
    }

    render() {
        const { trip } = this.props;
        const { isToRecenter } = this.state;
        return (
            <MapContainer className="map-container" bounds={this.getBounds(trip)} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                <SetBounds bounds={this.getBounds(trip)} isToRecenter={isToRecenter} />
                <Pane>
                    {this.renderPath(trip)}
                </Pane>
                <Pane>
                    {this.renderPathStops(trip)}
                </Pane>
            </MapContainer>
        );
    }
}

function SetBounds({ bounds, isToRecenter }) {
    const map = useMap();
    if (isToRecenter) {
        map.fitBounds(bounds);
    }
    return null;
}
